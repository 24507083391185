import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { GetNoteType } from '../../@types/getTemplatesType';
import URLConstants from '../../constants/urlConstants';


type GetTemplatesState = {
    isLoading: boolean;
    error: boolean;
    notes: []
}

const initialState: GetTemplatesState = {
    isLoading: false,
    error: false,
    notes: []
}

const slice = createSlice({
    name: 'getNotes',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ALL TEMPLATES
        getNotesSuccess(state, action) {
            state.isLoading = false;
            state.notes = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getAllNotes(conversationId:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.ALL_NOTES_BASE_URL+`?conversationId=${conversationId}&page=1&records=20`);
            dispatch(slice.actions.getNotesSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function addNotes(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.ADD_NOTES_BASE_URL,values);
            dispatch(getAllNotes(values.conversationId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}
export function editNotes(values: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${URLConstants.ADD_NOTES_BASE_URL}/${values.conversationNoteId}`,{note:values.note});
            dispatch(getAllNotes(values.conversationId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function deleteNotes(conversationId:any,conversationNoteId:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`${URLConstants.DELETE_NOTES_BASE_URL}`,{data:{'conversationNoteIds':[conversationNoteId]}});
            dispatch(getAllNotes(conversationId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}
