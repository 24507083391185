import React from 'react';
import { RootState, useSelector } from '../redux-persist/store';
import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import classnames from "classnames";

//i18n

function LeftMenu(props:any) {
    const  {customer} = useSelector(
        (state: RootState) => state.customer
    );

    return (
       <>
          <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="home">
                            <NavLink id="pills-user-tab" className={classnames({ active: props.activeTab === 'home' })} onClick={() => { props.toggleTab('home'); }}>
                                <i className="ri-home-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="home" placement="top">
                            Home
                        </UncontrolledTooltip>

                     
                        
                        <NavItem id="Chats">
                            <NavLink id="pills-chat-tab" className={classnames({ active: props.activeTab === 'chat' })} onClick={() => { props.toggleTab('chat'); }}>
                                <i className="ri-message-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Chats
                        </UncontrolledTooltip>
                        <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: props.activeTab === 'contacts' })} onClick={() => { props.toggleTab('contacts'); }}>
                                <i className="ri-group-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contacts
                        </UncontrolledTooltip>
                        <NavItem id="broadcast">
                            <NavLink id="pills-broadcast-tab" className={classnames({ active: props.activeTab === 'broadcast' })} onClick={() => { props.toggleTab('broadcast'); }}>
                                <i className="ri-broadcast-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="broadcast" placement="top">
                        Broadcast
                        </UncontrolledTooltip>
                        <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: props.activeTab === 'settings' })} onClick={() => { props.toggleTab('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip>
                        <NavItem id="Reports">
                            <NavLink id="pills-report-tab" className={classnames({ active: props.activeTab === 'reports' })} onClick={() => { props.toggleTab('reports'); }}>
                              <i className="ri-file-chart-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Reports" placement="top">
                            Reports
                        </UncontrolledTooltip>
                      {customer.accountId === "804353b9-c25a-43d4-82e7-f552b2552adb" && <>
                        <NavItem id="Chatbot">
                            <NavLink id="pills-chatbot-tab" className={classnames({ active: props.activeTab === 'chatbot' })} onClick={() => { props.toggleTab('chatbot'); }}>
                              <i className="ri-chat-settings-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chatbot" placement="top">
                            Chatbot builder
                        </UncontrolledTooltip>
                        </> }
                    </Nav>
                </div>
       </>
    );
}

export default LeftMenu;