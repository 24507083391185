import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DownloadAnalyticsType } from '../../@types/downloadAnalyticsType';
import URLConstants from '../../constants/urlConstants';

type DownloadAnalyticsState = {
    isLoading: boolean;
    error: boolean;
    downloadChat: DownloadAnalyticsType;
}

const initialState: DownloadAnalyticsState = {
    isLoading: false,
    error: false,
    downloadChat: { } as DownloadAnalyticsType
}

const slice = createSlice({
    name: 'downloadChat',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET  CHAT FILE LINK OF MESSAGES
        getMessageChatDownloadSuccess(state, action) {
            state.isLoading = false;
            state.downloadChat = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;


export function chatDownloadLink(conversationId:string) {
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(`${URLConstants.DOwNLOAD_CHAT_BASE_URL}/${conversationId}/report`);
                window.location.href =  response.data.data.url
                dispatch(slice.actions.getMessageChatDownloadSuccess(response.data.data));               
            } catch (error:any) {
                dispatch(slice.actions.hasError(error));
                return {message : error?.message, hasError : true}
            }
        };
}

export function chatBotReportDownloadLink(from:string,to :string,accountId:string) {
    var params = {
        "from": from,
        "to": to
    }

    let botConfigurationId:string

    if(accountId =="861bba5c-8317-487d-a88b-5ed96e6f83ac"){
        botConfigurationId ="ba1004a8-5061-49db-8d85-96b63b6d3616";
    }
    else{
        botConfigurationId = "ba1004a8-5061-49db";
    }
    
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${URLConstants.DOWNLOAD_CHATBOT_REPORT_BASE_URL}/${botConfigurationId}/download`,{params});
           window.location.href =  response.data.data.url
            dispatch(slice.actions.getMessageChatDownloadSuccess(response.data.data));    
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true}
        }
    };
}