import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { CreateWBAccount,UpdateWBAccount } from '../../@types/createWBAccount';
import URLConstants from '../../constants/urlConstants';


type CreateWBAccountState = {
    isLoading: boolean;
    error: boolean;
    createWBAccount: CreateWBAccount;
    updateWBAccount: UpdateWBAccount;
}

const initialState: CreateWBAccountState = {
    isLoading: false,
    error: false,
    createWBAccount: {} as CreateWBAccount,
    updateWBAccount:{} as UpdateWBAccount
}

const slice = createSlice({
    name: 'createWBAccountState',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // CREATE WB ACCOUNT
        createWBAccountSuccess(state, action) {
            state.isLoading = false;
            state.createWBAccount = action.payload;
        },

        // UPDATE WB ACCOUNT
        updateWBAccountSuccess(state, action) {
            state.isLoading = false;
            state.updateWBAccount = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function createNewWBAccount(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.CREATE_WB_ACCOUNT_BASE_URL,{"displayName":values.displayName,'businessAccountId':values.businessAccountId,'apiKey':values.serverKey});
            dispatch(slice.actions.createWBAccountSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function updateWBAccountDetails(whatsAppBusinessId:string,values:any) {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${URLConstants.UPDATE_WB_ACCOUNT_BASE_URL}`+`${whatsAppBusinessId}`,{"displayName":values.displayName, 'businessAccountId': values.businessAccountId,'apiKey':values.serverKey});
            dispatch(slice.actions.updateWBAccountSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}