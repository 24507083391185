import { useMemo, ReactNode } from "react";
import { CssBaseline } from "@material-ui/core";
import {
	ThemeProvider,
	ThemeOptions,
	createTheme,
} from "@material-ui/core/styles";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";

type ThemeConfigProps = {
	children: ReactNode;
};

export default function ThemeConfig({ children }: ThemeConfigProps) {


	const themeOptions: ThemeOptions = useMemo(
		() => ({
            palette: {
                action: {
                  disabled: '#ffffff',
                },
              }
		}),
		[]
	);

	const theme = createTheme(themeOptions);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}