import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import URLConstants from '../../constants/urlConstants';

type SendMessage = {
    message: string;
}

type SendMessageState = {
    isLoading: boolean;
    error: boolean;
    sendMessage: SendMessage;
}

const initialState: SendMessageState = {
    isLoading: false,
    error: false,
    sendMessage: {
        message: ''
    },
}

const slice = createSlice({
    name: 'sendMessage',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SEND MESSAGE
        sendMessageSuccess(state, action) {
            state.isLoading = false;
            state.sendMessage = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function sendNewMessage(to: any, templateName: any, language: any, header: any, body: any, button: any) {

    if (Object.keys(header).length === 0) {
        header = null;
    }
    if (Object.keys(body).length === 0) {
        body = null;
    }
    let templateDetails = {'to': to[0].contactPhoneNumber, templateName,language, header,  body, button}
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.SEND_MESSAGE_BASE_URL, templateDetails);
            dispatch(slice.actions.sendMessageSuccess(response.data.data));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };

}