import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
import { QuickReply } from '../../@types/quickReply';
import URLConstants from '../../constants/urlConstants';


type QuickReplyState = {
    isLoading: boolean;
    error: boolean;
    quickReply: QuickReply;
}

const initialState: QuickReplyState = {
    isLoading: false,
    error: false,
    quickReply: {
        "quickReplyId": "",
        "whatsAppBusinessId": "",
        "replyText": "",
        "startTime": "",
        "endTime": "",
        "isTimeSet": false,
        "createTime": new Date(),
        "updateTime": new Date()
    }
}

const slice = createSlice({
    name: 'quickReply',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET QUICK REPLY
        getQuickReplySuccess(state, action) {
            state.isLoading = false;
            state.quickReply = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getQuickReply(whatsAppBusinessId:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.QUICK_REPLY_BASE_URL+`/${whatsAppBusinessId}`);
            dispatch(slice.actions.getQuickReplySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createQuickReply(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post(URLConstants.QUICK_REPLY_BASE_URL, values);
            dispatch(getQuickReply(values.whatsAppBusinessId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function editQuickReply(quickReplyId:string,values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.put(URLConstants.QUICK_REPLY_BASE_URL+`/${quickReplyId}`, values);
            dispatch(getQuickReply(values.whatsAppBusinessId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function deleteQuickReply(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(URLConstants.QUICK_REPLY_BASE_URL+`/${values.quickReplyId}`);
            dispatch(getQuickReply(values.whatsAppBusinessId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}

export function enableOrDisableQuickReply(values:any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.patch(URLConstants.QUICK_REPLY_ENABLE_BASE_URL,values);
            dispatch(getQuickReply(values.whatsAppBusinessId));
        } catch (error:any) {
            dispatch(slice.actions.hasError(error));
            return {message : error?.message, hasError : true} 
        }
    };
}



